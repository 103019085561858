import { graphql } from "gatsby"
import React from "react"
import { ArticleCardComponent } from "../../components"
import {
  ArticlesGridContainer,
  MainContainer,
  PageContainer,
  PageHeaders,
  PageHeading,
  SectionSpacer,
} from "../../elements"
import { Layout } from "../../Layout"
import { capitalize } from "../../utils"

interface BlogProps {
  data: any
  pageContext: any
}

const Blog: React.FC<BlogProps> = ({ data, pageContext }) => {
  const { activeType } = pageContext

  const articles = data.allMdx
  return (
    <Layout
      title={
        activeType === "guide"
          ? `Shoe ${capitalize(activeType)}s & Reviews`
          : capitalize(activeType)
      }
      description="What's best for your feet. Get advises, reviews and guides to help you choose the best shoes and footwear."
      pathname={`/${activeType}`}
    >
      <PageHeaders>
        <MainContainer>
          <PageHeading>
            <h2>
              {activeType === "guide"
                ? `Shoe ${capitalize(activeType)}s & Reviews`
                : capitalize(activeType)}
            </h2>
          </PageHeading>
        </MainContainer>
      </PageHeaders>

      <PageContainer>
        <MainContainer>
          <ArticlesGridContainer>
            {articles.edges.map(article => (
              <ArticleCardComponent
                type={article.node.frontmatter.type}
                key={article.node.id}
                title={article.node.frontmatter.title}
                description={article.node.frontmatter.description}
                updatedDate={article.node.frontmatter.date}
                slug={article.node.slug}
                timeToRead={article.node.timeToRead}
                fixed={article.node.frontmatter.featureImage.childImageSharp}
              />
            ))}
          </ArticlesGridContainer>
        </MainContainer>
      </PageContainer>

      <SectionSpacer marginTop="2em" marginBottom="2em" />
    </Layout>
  )
}
export default Blog

export const pageQuery = graphql`
  query ($activeType: String!) {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { published: { eq: true }, type: { eq: $activeType } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          timeToRead
          frontmatter {
            description
            title
            type
            date(formatString: "MMMM DD, YYYY")
            featureImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
